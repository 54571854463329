@import url(https://fonts.googleapis.com/css2?family=Exo:ital,wght@1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Russo+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Courgette&display=swap);
* {
  margin: 0;
}
* > a {
  text-decoration: none;
}

body {
  background-color: white !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.banner {
  height: 100%;
  object-fit: contain;
}

.banner__content {
  padding-top: 130px;
}

.gym .swiper-pagination-bullet {
  background-color: #ed2727 !important;
}
.gym .swiper-button-prev,
.gym .swiper-button-next {
  color: #ed2727 !important;
}

.yoga .swiper-pagination-bullet {
  background-color: #a53ead !important;
}
.yoga .swiper-button-prev,
.yoga .swiper-button-next {
  color: #de7be6 !important;
}

.functional .swiper-pagination-bullet {
  background-color: #f0c902 !important;
}
.functional .swiper-button-prev,
.functional .swiper-button-next {
  color: #f0c902 !important;
}

.bodyweight .swiper-pagination-bullet {
  background-color: #4c1679 !important;
}
.bodyweight .swiper-button-prev,
.bodyweight .swiper-button-next {
  color: #4c1679 !important;
}

.swiper-scrollbar {
  display: none !important;
}

.info__text {
  margin-right: 50px;
  margin-left: 50px;
}

@media only screen and (max-width: 720px) {
  .info__text {
    margin-right: 30px;
    margin-left: 30px;
  }
}

.swiper-pagination-bullet {
  background-color: white !important;
}
.swiper-button-prev,
.swiper-button-next {
  color: white !important;
}

.yoga__buttons {
  color: black !important;
  background-color: white !important;
  font-family: "Courgette", cursive !important;
  font-size: 12px !important;
  margin-right: 3px !important;
  margin-left: 3px !important;
  transition: all 0.3s ease-in-out !important;
  padding: 12px !important;
}
.yoga__buttons:hover {
  background-color: #ed5378 !important;
  color: white !important;
}

.yoga__cards_container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.yoga__card {
  height: 540px;
  padding: 0 auto;
  flex-basis: 29%;
  display: grid;
  place-items: center;
  box-sizing: border-box;
}

.MuiInputBase-root {
  color: white !important;
}

@media only screen and (max-width: 1200px) {
  .yoga__card {
    flex-basis: 33%;
  }
}

@media only screen and (max-width: 1050px) {
  .yoga__card {
    flex-basis: 37%;
  }
}

.cards__item__img {
  max-height: 520px;
  margin-bottom: 15px;
  margin-top: 15px;
  margin: 0;
  object-fit: contain;
  border-radius: 10px;
}

.cards__item__wrap {
  max-height: 520px;
  max-width: 500px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}


@media only screen and (max-width: 1200px) {
   .cards__item__wrap {
    max-width: 440px;
  }
}
.rules__text {
  margin-left: 30px;
  margin-right: 30px;
}

.rules__text > p {
  font-family: "Russo One", sans-serif !important;
  color: white;
  text-align: justify;
  font-size: 20px;
}

.rules__text > h3 {
  color: white;
  font-size: 30px;
  margin-bottom: 15px;
  font-family: "Exo", sans-serif;
  text-align: center;
}

.body__buttons {
  display: flex;
  flex-direction: column;
}

.play__button {
  font-weight: 800 !important;
  margin-right: 10px;
  font-size: 1.65rem !important;
  padding: 10px !important;
  width: 350px;
  font-family: "Russo One", sans-serif !important;
}
.buy__button {
  margin-right: 10px !important;
  margin-bottom: 7px !important;
  font-size: 1.65rem !important;
  font-weight: 800 !important;
  background-color: #1b8433 !important;
  padding: 10px !important;
  width: 350px;
  font-family: "Russo One", sans-serif !important;
}

.video__button {
  margin-right: 10px !important;
  margin-bottom: 7px !important;
  font-size: 1.65rem !important;
  font-weight: 800 !important;
  padding: 10px !important;
  width: 350px;
  font-family: "Russo One", sans-serif !important;
}

.buy__button:hover {
  opacity: 0.9;
}

.play__button:hover {
  opacity: 0.9;
}

.timer__buttons {
  font-family: "Russo One", sans-serif !important;
}

.timer__buttons:hover {
  opacity: 0.9;
}
@media screen and (max-width: 960px) {
  .body__buttons {
    display: flex;
    flex-direction: column;
  }

  .buy__button {
    margin-bottom: 5px !important;
    margin-right: 0px !important;
  }
}

@media screen and (max-width: 768px) {
}

