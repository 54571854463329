.info__text {
  margin-right: 50px;
  margin-left: 50px;
}

@media only screen and (max-width: 720px) {
  .info__text {
    margin-right: 30px;
    margin-left: 30px;
  }
}
