.rules__text {
  margin-left: 30px;
  margin-right: 30px;
}

.rules__text > p {
  font-family: "Russo One", sans-serif !important;
  color: white;
  text-align: justify;
  font-size: 20px;
}

.rules__text > h3 {
  color: white;
  font-size: 30px;
  margin-bottom: 15px;
  font-family: "Exo", sans-serif;
  text-align: center;
}
