.gym .swiper-pagination-bullet {
  background-color: #ed2727 !important;
}
.gym .swiper-button-prev,
.gym .swiper-button-next {
  color: #ed2727 !important;
}

.yoga .swiper-pagination-bullet {
  background-color: #a53ead !important;
}
.yoga .swiper-button-prev,
.yoga .swiper-button-next {
  color: #de7be6 !important;
}

.functional .swiper-pagination-bullet {
  background-color: #f0c902 !important;
}
.functional .swiper-button-prev,
.functional .swiper-button-next {
  color: #f0c902 !important;
}

.bodyweight .swiper-pagination-bullet {
  background-color: #4c1679 !important;
}
.bodyweight .swiper-button-prev,
.bodyweight .swiper-button-next {
  color: #4c1679 !important;
}

.swiper-scrollbar {
  display: none !important;
}
