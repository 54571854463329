.body__buttons {
  display: flex;
  flex-direction: column;
}

.play__button {
  font-weight: 800 !important;
  margin-right: 10px;
  font-size: 1.65rem !important;
  padding: 10px !important;
  width: 350px;
  font-family: "Russo One", sans-serif !important;
}
.buy__button {
  margin-right: 10px !important;
  margin-bottom: 7px !important;
  font-size: 1.65rem !important;
  font-weight: 800 !important;
  background-color: #1b8433 !important;
  padding: 10px !important;
  width: 350px;
  font-family: "Russo One", sans-serif !important;
}

.video__button {
  margin-right: 10px !important;
  margin-bottom: 7px !important;
  font-size: 1.65rem !important;
  font-weight: 800 !important;
  padding: 10px !important;
  width: 350px;
  font-family: "Russo One", sans-serif !important;
}

.buy__button:hover {
  opacity: 0.9;
}

.play__button:hover {
  opacity: 0.9;
}

.timer__buttons {
  font-family: "Russo One", sans-serif !important;
}

.timer__buttons:hover {
  opacity: 0.9;
}
@media screen and (max-width: 960px) {
  .body__buttons {
    display: flex;
    flex-direction: column;
  }

  .buy__button {
    margin-bottom: 5px !important;
    margin-right: 0px !important;
  }
}

@media screen and (max-width: 768px) {
}
