.yoga__buttons {
  color: black !important;
  background-color: white !important;
  font-family: "Courgette", cursive !important;
  font-size: 12px !important;
  margin-right: 3px !important;
  margin-left: 3px !important;
  transition: all 0.3s ease-in-out !important;
  padding: 12px !important;
}
.yoga__buttons:hover {
  background-color: #ed5378 !important;
  color: white !important;
}

.yoga__cards_container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.yoga__card {
  height: 540px;
  padding: 0 auto;
  flex-basis: 29%;
  display: grid;
  place-items: center;
  box-sizing: border-box;
}

.MuiInputBase-root {
  color: white !important;
}

@media only screen and (max-width: 1200px) {
  .yoga__card {
    flex-basis: 33%;
  }
}

@media only screen and (max-width: 1050px) {
  .yoga__card {
    flex-basis: 37%;
  }
}
