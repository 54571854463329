.cards__item__img {
  max-height: 520px;
  margin-bottom: 15px;
  margin-top: 15px;
  margin: 0;
  -o-object-fit: contain;
  object-fit: contain;
  border-radius: 10px;
}

.cards__item__wrap {
  max-height: 520px;
  max-width: 500px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}


@media only screen and (max-width: 1200px) {
   .cards__item__wrap {
    max-width: 440px;
  }
}